//import { useNavigate } from "react-router-dom";
//import { useHistory } from 'react-router-dom';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth, fixDateToStringify } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportState {
	isLoading: boolean;
	sort?: string;
	direction?: string;
	initialized?: boolean;
	reportData?: ReportData;
	reportFilter?: ReportFilter;
}

export interface ReportFilter {
	name?: string;
	startDate?: Date;
	endDate?: Date;
}

export interface ReportData {
	reportData1?: ReportData1[];
	reportData2?: ReportData2[];
	reportData3?: ReportData3[];
	reportData4?: ReportData4[];
	reportData5?: ReportData5[];
	reportData6?: ReportData6[];
	meetingDays?: MeetingDay[];
	reportData7?: ReportData7[];
}

export interface ReportData1 {
	cik?: number;
	name?: string;
	dateFiled?: Date;
	series?: number;
	classes?: number;
}

export interface ReportData2 {
	cik?: number;
	companyName: string;
	formType: string;
	san: string;
	dateFiled: Date;
	date: string;
	funds: string;
	series?: number;
	classes?: number;
	reportDate: Date;
	fileName: string;
	recordDate?: Date;
	meetingDate?: Date;
	meetingType?: string;
	cost?: string;
	solicitor?: string;
	fundCounsel?: string;
	proposal1?: string;
	proposal2?: string;
	proposal3?: string;
	proposal4?: string;
	proposal5?: string;
	proposal6?: string;
	proposal7?: string;
	note?: string;
}

export interface ReportData3 {
	year?: number;
	proxyCount?: number;
}

export interface ReportData4 {
	solicitor?: string;
	proxyCount?: number;
}

export interface ReportData5 {
	meetingDate?: Date;
	proxyCount?: number;
	annual?: number;
	special?: number;
	routine?: number;
	nonRoutine?: number;
	openEnd?: number;
	closedEnd?: number;
}

export interface MeetingDay {
	meetingDay?: string;
	proxyCount?: number;
	annual?: number;
	special?: number;
}

// Meeting Type Report
export interface ReportData6 {
	meetingType?: string;
	proxyCount?: number;
	proxyCount19?: number;
	proxyCount20?: number;
	proxyCount21?: number;
	proxyCount22?: number;
	proxyCount23?: number;
	proxyCount24?: number;
	routine?: number;
	nonRoutine?: number;
}

// Family Report
export interface ReportData7 {
	name?: string;
	proxyCount?: number;
	proxyCount19?: number;
	proxyCount20?: number;
	proxyCount21?: number;
	proxyCount22?: number;
	proxyCount23?: number;
	proxyCount24?: number;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestReportAction {
	type: 'REQUEST_REPORT_DATA';
}

interface ReceiveReportAction {
	type: 'RECEIVE_REPORT_DATA';
	reportFilter?: ReportFilter;
	reportData?: ReportData;
}

interface ResetStateAction {
	type: 'RESET_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestReportAction | ReceiveReportAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestReport: (reportFilter: ReportFilter): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		//const navigate = useNavigate();
		//const history = useHistory();
		if (appState && appState.reportState && (!appState.reportState.reportFilter || appState.reportState.reportFilter.name != reportFilter.name) && reportFilter.name) {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(fixDateToStringify(reportFilter))
			};
			fetchWithAuth({ url: `api/edgar/report` }, requestOptions)
				.then(response => {
					if (response.ok) {
						return response.json() as Promise<ReportData>;
					} else {
						return Promise.reject(response);
					}
				})
				.then(reportData => {
					dispatch({ type: 'RECEIVE_REPORT_DATA', reportData: reportData, reportFilter: reportFilter });
				})
				.catch(error => {
					console.error('There was an error!', error);
					if (error.status === 401) { // Unauthorized
						// Redirect to Login? Regenerate Session and Resubmit?
						//this.props.history.push("/authentication/logout");
						//navigate("/authentication/logout");
						//history.push("/authentication/logout");
					}
				});

			dispatch({ type: 'REQUEST_REPORT_DATA' });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateReport: ReportState = { reportData: {}, reportFilter: {}, isLoading: false, initialized: false };

export const reducer: Reducer<ReportState> = (state: ReportState | undefined, incomingAction: Action): ReportState => {
	if (state === undefined) {
		return unloadedStateReport;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				reportFilter: {},
				reportData: {},
				isLoading: false,
				initialized: false
			};
		case 'REQUEST_REPORT_DATA':
			return {
				reportFilter: state.reportFilter,
				reportData: state.reportData,
				isLoading: true,
				initialized: false
			};
		case 'RECEIVE_REPORT_DATA':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			return {
				reportFilter: action.reportFilter,
				reportData: action.reportData,
				isLoading: false,
				initialized: true
			};
			break;
	}

	return state;
};
